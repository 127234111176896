<template>
  <b-card title="Налаштування розділів">
    <b-row>
      <b-col sm="12" class="text-right">
        <b-btn-group>
          <b-button size="sm" variant="success" @click="openModal"><fa icon="plus" /></b-button>
        </b-btn-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <br>
        <table class="table">
          <thead>
          <tr>
            <th>{{ $t('name') }}</th>
            <th class="text-right">Дії</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.name }}</td>
            <td class="text-right">
              <b-btn-group>
                <b-button size="sm" variant="warning" @click="edit(item.id)"><fa icon="pencil-alt" /></b-button>
                <b-button size="sm" variant="danger" @click="remove(item.id)"><fa icon="times" /></b-button>
              </b-btn-group>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-modal id="parts-modal" size="sm" hide-footer :title="form.id ? 'Змінити' : 'Додати'">
      <div class="form-group">
        <label for="name">{{ $t('name') }}</label>
        <input type="text" v-model="form.name" required class="form-control form-control-sm">
      </div>
      <div v-for="(item, index) in types" :key="index" class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" :id="'types-' + item.id" name="types" v-model="form.types" :value="item.id">
        <label class="custom-control-label" :for="'types-' + item.id">{{ item.name }}</label>
      </div>
      <b-button size="sm" variant="primary" @click="save">{{ $t('save') }}</b-button>
    </b-modal>
  </b-card>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
export default {
  data: () => ({
    form: new Form({
      id: null,
      name: '',
      types: []
    }),
    items: null,
    types: null
  }),
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'operations/parts').then(resp => {
        if (resp.data) {
          this.items = resp.data.parts
          this.types = resp.data.types
        }
      })
    },
    save () {
      if (this.form.id) {
        this.updatePart()
      } else {
        this.addPart()
      }
    },
    async addPart () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await this.form.post(apiUrl + 'operations/parts').then(resp => {
        if (resp.data) {
          this.getData()
          this.form.reset()
          this.$bvModal.hide('parts-modal')
          this.$root.$refs.loading.finish()
        }
      })
    },
    async updatePart () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await this.form.put(apiUrl + 'operations/parts/' + this.form.id).then(resp => {
        if (resp.data) {
          this.getData()
          this.form.reset()
          this.$bvModal.hide('parts-modal')
          this.$root.$refs.loading.finish()
        }
      })
    },
    edit (id) {
      this.$bvModal.show('parts-modal')
      const item = this.items.find(i => i.id === id)
      this.form.fill(item)
      this.form.types = item.types.map(i => {
        return i.id
      })
    },
    async remove (id) {
      this.$root.$refs.loading.start()
      const result = confirm(this.$t('want_to_delete'))
      if (result) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.delete(apiUrl + 'operations/parts/' + id).then(resp => {
          if (resp.data.status) {
            this.getData()
            this.$root.$refs.loading.finish()
          }
        })
      }
    },
    openModal () {
      this.form.reset()
      this.$bvModal.show('parts-modal')
    },
  }
}
</script>
