<template>
  <component v-if="slug" :is="slug" />
</template>
<script>
import Parts from './parts/edit'
import Types from './types/edit'
export default {
  components: {
    parts: Parts,
    types: Types
  },
  data: () => ({
    slug: null
  }),
  mounted () {
    this.slug = this.$route.params.slug
  }
}
</script>
