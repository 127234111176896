<template>
  <b-card title="Типи операцій">
    <b-row>
      <b-col sm="12" class="text-right">
        <b-btn-group>
          <b-button size="sm" variant="success" @click="openModal"><fa icon="plus" /></b-button>
          <router-link :to="{ name: 'operation.types.additional' }" class="btn btn-sm btn-primary">Додатковий функціонал</router-link>
        </b-btn-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <br>
        <table class="table">
          <thead>
          <tr>
            <th>{{ $t('name') }}</th>
            <th class="text-right">Дії</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.name }}</td>
            <td class="text-right">
              <b-btn-group>
                <b-button size="sm" variant="warning" @click="edit(item.id)"><fa icon="pencil-alt" /></b-button>
                <b-button size="sm" variant="danger" @click="remove(item.id)"><fa icon="times" /></b-button>
              </b-btn-group>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-modal id="types-modal" size="sm" hide-footer :title="form.id ? 'Змінити' : 'Додати'">
      <div class="form-group">
        <label for="name">{{ $t('name') }}</label>
        <input type="text" v-model="form.name" required class="form-control form-control-sm">
      </div>
      <div v-for="(item, index) in additional" :key="index" class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" :id="'additional-' + item.id" name="additional" v-model="form.additional" :value="item.id" @change="setAdditional">
        <label class="custom-control-label" :for="'additional-' + item.id">{{ item.name }}</label>
      </div>
      <b-button size="sm" variant="primary" @click="save">{{ $t('save') }}</b-button>
    </b-modal>
  </b-card>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
export default {
  data: () => ({
    form: new Form({
      id: null,
      name: '',
      additional: []
    }),
    items: null,
    additional: null
  }),
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'operations/types').then(resp => {
        if (resp.data) {
          this.items = resp.data.types
          this.additional = resp.data.additional
        }
      })
    },
    save () {
      if (this.form.id) {
        this.updateType()
      } else {
        this.addType()
      }
    },
    async addType () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await this.form.post(apiUrl + 'operations/types').then(resp => {
        if (resp.data) {
          this.getData()
          this.form.reset()
          this.$bvModal.hide('types-modal')
          this.$root.$refs.loading.finish()
        }
      })
    },
    async updateType () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await this.form.put(apiUrl + 'operations/types/' + this.form.id).then(resp => {
        if (resp.data) {
          this.getData()
          this.form.reset()
          this.$bvModal.hide('types-modal')
          this.$root.$refs.loading.finish()
        }
      })
    },
    edit (id) {
      this.$bvModal.show('types-modal')
      const item = this.items.find(i => i.id === id)
      this.form.fill(item)
      this.form.additional = item.additional.map(i => {
        return i.id
      })
    },
    async remove (id) {
      this.$root.$refs.loading.start()
      const result = confirm(this.$t('want_to_delete'))
      if (result) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.delete(apiUrl + 'operations/types/' + id).then(resp => {
          if (resp.data.status) {
            this.getData()
            this.$root.$refs.loading.finish()
          }
        })
      }
    },
    openModal () {
      this.form.reset()
      this.$bvModal.show('types-modal')
    },
    setAdditional (e) {
      const checked = e.target.checked
      this.form.additional = []
      if (checked) {
        this.form.additional.push(e.target.value)
      }
    }
  }
}
</script>
